.react-add-to-calendar {
  z-index: 10000;
}

.react-add-to-calendar__button {
  color: #47535f;
}

.react-add-to-calendar__dropdown ul {
  padding-left: 0rem;

  z-index: 10000;
  li {
    padding-top: 1.5rem;
    list-style-type: none;
    text-align: left;
    font-size: 1.8rem;
    font-weight: bold !important;

    &:hover {
      transition: 0.15s;
      transform: scale(1.05);
    }

    a {
      color: #47535fd5;

      i {
        display: none;
      }
    }
  }
}

.react-add-to-calendar-button {
  z-index: 100;
}
