﻿//Positioning
.relative {
  position: relative;
}

.static {
  position: static;
}

//Display
.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-flex {
  display: flex;
}

//Width
.w-auto {
  width: auto;
}

.w-0 {
  width: 0;
}

.w-50 {
  width: 50%;
}

.w-100 {
  width: 100%;
}

//Max Width
.mw-none {
  max-width: none;
}

.mw-0 {
  max-width: 0;
}

.mw-50 {
  max-width: 50%;
}

.mw-100 {
  max-width: 100%;
}

//Height
.h-auto {
  height: auto;
}

.h-0 {
  height: 0;
}

.h-50 {
  height: 50%;
}

.h-100 {
  height: 100%;
}

//Max Height
.mh-none {
  max-height: none;
}

.mh-0 {
  max-height: 0;
}

.mh-50 {
  max-height: 50%;
}

.mh-100 {
  max-height: 100%;
}

// ** SPACING **
//Padding
.p-0 {
  padding: 0;
}

.p-xxs {
  padding: $space-xxs;
}

.p-xs {
  padding: $space-xs;
}

.p-s {
  padding: $space-s;
}

.p-m {
  padding: $space-m;
}

.p-l {
  padding: $space-l;
}

.p-xl {
  padding: $space-xl;
}

.p-xxl {
  padding: $space-xxl;
}

.py-xxs {
  padding: $space-xxs 0;
}

.py-xs {
  padding: $space-xs 0;
}

.py-s {
  padding: $space-s 0;
}

.py-m {
  padding: $space-m 0;
}

.py-l {
  padding: $space-l 0;
}

.py-xl {
  padding: $space-xl 0;
}

.py-xxl {
  padding: $space-xxl 0;
}

.px-xxs {
  padding: 0 $space-xxs;
}

.px-xs {
  padding: 0 $space-xs;
}

.px-s {
  padding: 0 $space-s;
}

.px-m {
  padding: 0 $space-m;
}

.px-l {
  padding: 0 $space-l;
}

.px-xl {
  padding: 0 $space-xl;
}

.px-xxl {
  padding: 0 $space-xxl;
}

.pt-xxs {
  padding-top: $space-xxs;
}

.pt-xs {
  padding-top: $space-xs;
}

.pt-s {
  padding-top: $space-s;
}

.pt-m {
  padding-top: $space-m;
}

.pt-l {
  padding-top: $space-l;
}

.pt-xl {
  padding-top: $space-xl;
}

.pt-xxl {
  padding-top: $space-xxl;
}

.pr-0 {
  padding-right: 0;
}

.pr-xxs {
  padding-right: $space-xxs;
}

.pr-xs {
  padding-right: $space-xs;
}

.pr-s {
  padding-right: $space-s;
}

.pr-m {
  padding-right: $space-m;
}

.pr-l {
  padding-right: $space-l;
}

.pr-xl {
  padding-right: $space-xl;
}

.pr-xxl {
  padding-right: $space-xxl;
}

.pb-0 {
  padding-bottom: 0;
}

.pb-xxs {
  padding-bottom: $space-xxs;
}

.pb-xs {
  padding-bottom: $space-xs;
}

.pb-s {
  padding-bottom: $space-s;
}

.pb-m {
  padding-bottom: $space-m;
}

.pb-l {
  padding-bottom: $space-l;
}

.pb-xl {
  padding-bottom: $space-xl;
}

.pb-xxl {
  padding-bottom: $space-xxl;
}

.pl-0 {
  padding-left: 0;
}

.pl-xxs {
  padding-left: $space-xxs;
}

.pl-xs {
  padding-left: $space-xs;
}

.pl-s {
  padding-left: $space-s;
}

.pl-m {
  padding-left: $space-m;
}

.pl-l {
  padding-left: $space-l;
}

.pl-xl {
  padding-left: $space-xl;
}

.pl-xxl {
  padding-left: $space-xxl;
}

//Margin
.m-0 {
  margin: 0;
}

.m-xxs {
  margin: $space-xxs;
}

.m-xs {
  margin: $space-xs;
}

.m-s {
  margin: $space-s;
}

.m-m {
  margin: $space-m;
}

.m-l {
  margin: $space-l;
}

.m-xl {
  margin: $space-xl;
}

.m-xxl {
  margin: $space-xxl;
}

.mt-xxs {
  margin-top: $space-xxs;
}

.my-xxs {
  margin: $space-xxs 0;
}

.my-xs {
  margin: $space-xs 0;
}

.my-s {
  margin: $space-s 0;
}

.my-m {
  margin: $space-m 0;
}

.my-l {
  margin: $space-l 0;
}

.my-xl {
  margin: $space-xl 0;
}

.my-xxl {
  margin: $space-xxl 0;
}

.mx-xxs {
  margin: 0 $space-xxs;
}

.mx-xs {
  margin: 0 $space-xs;
}

.mx-s {
  margin: 0 $space-s;
}

.mx-m {
  margin: 0 $space-m;
}

.mx-l {
  margin: 0 $space-l;
}

.mx-xl {
  margin: 0 $space-xl;
}

.mx-xxl {
  margin: 0 $space-xxl;
}

.mt-0 {
  margin-top: 0;
}

.mt-xs {
  margin-top: $space-xs;
}

.mt-s {
  margin-top: $space-s;
}

.mt-m {
  margin-top: $space-m;
}

.mt-l {
  margin-top: $space-l;
}

.mt-xl {
  margin-top: $space-xl;
}

.mt-xxl {
  margin-top: $space-xxl;
}

.mr-0 {
  margin-right: 0;
}

.mr-xxs {
  margin-right: $space-xxs;
}

.mr-xs {
  margin-right: $space-xs;
}

.mr-s {
  margin-right: $space-s;
}

.mr-m {
  margin-right: $space-m;
}

.mr-l {
  margin-right: $space-l;
}

.mr-xl {
  margin-right: $space-xl;
}

.mr-xxl {
  margin-right: $space-xxl;
}

.mb-0 {
  margin-bottom: 0;
}

.mb-xxs {
  margin-bottom: $space-xxs;
}

.mb-xs {
  margin-bottom: $space-xs;
}

.mb-s {
  margin-bottom: $space-s;
}

.mb-m {
  margin-bottom: $space-m;
}

.mb-l {
  margin-bottom: $space-l !important;
}

.mb-xl {
  margin-bottom: $space-xl;
}

.mb-xxl {
  margin-bottom: $space-xxl;
}

.ml-0 {
  margin-left: 0;
}

.ml-xxs {
  margin-left: $space-xxs;
}

.ml-xs {
  margin-left: $space-xs;
}

.ml-s {
  margin-left: $space-s;
}

.ml-m {
  margin-left: $space-m;
}

.ml-l {
  margin-left: $space-l;
}

.ml-xl {
  margin-left: $space-xl;
}

.ml-xxl {
  margin-left: $space-xxl;
}

// floats
.float-right {
  float: right;
}
.float-left {
  float: left;
}

//hidden
.hidden-sm {
  @media #{$mq-sm-min} {
    display: none !important;
  }
}

.hidden-md {
  @media #{$mq-md-min} {
    display: none !important;
  }
}

.hidden-lg {
  @media #{$mq-lg-min} {
    display: none !important;
  }
}

//visible
.visible-xs {
  display: none !important;

  @media #{$mq-sm-max} {
    display: flex !important;
  }
}

.visible-sm {
  display: none !important;

  @media #{$mq-sm-min} {
    display: flex !important;
  }
}

.visible-md {
  display: none !important;

  @media #{$mq-md-min} {
    display: flex !important;
  }
}

.visible-lg {
  display: none !important;

  @media #{$mq-lg-min} {
    display: flex !important;
  }
}

//Aspect Ratios
[class*="aspect-ratio"] {
  position: relative;
  overflow: hidden;

  img,
  iframe,
  > div {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: auto;
  }

  &:before {
    content: "";
    width: 100%;
  }
}

.aspect-ratio-16x9 {
  &:before {
    padding-bottom: 56.25%;
  }
}

.aspect-ratio-3x2 {
  &:before {
    padding-bottom: 66.6%;
  }
}

.aspect-ratio-3x1 {
  &:before {
    padding-bottom: 33.4%;
  }
}

.aspect-ratio-2x1 {
  &:before {
    padding-bottom: 50%;
  }
}

.aspect-ratio-1x1 {
  &:before {
    padding-bottom: 100%;
  }
}
