//Font variables
//Import rules must has ".css" at the end of the URL
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap.css");

@mixin font-primary-family() {
  font-family: "Montserrat", sans-serif;
}

@mixin font($fs: $default-font-size, $lh: $default-line-height) {
  font-size: $fs;
  line-height: $lh;
}

@mixin font-awesome-family($fontw: 400) {
  font-family: "Font Awesome 5 Pro";
  font-weight: $fontw;
}

// Text tags
body {
  @include font-primary-family();
  @include font();
  // normailise fonts
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.a-h1,
h1 {
  @include font(6rem, 8rem);
  font-weight: 700;
  margin-bottom: $space-m;

  @media #{$mq-md-max} {
    @include font(5rem, 6rem);
  }

  @media #{$mq-sm-max} {
    @include font(4rem, 5rem);
  }
}

.a-h2,
h2 {
  @include font(5rem, 6rem);
  font-weight: 700;
  margin-bottom: $space-m;

  @media #{$mq-md-max} {
    @include font(4rem, 5rem);
  }

  @media #{$mq-sm-max} {
    @include font(3.25rem, 4rem);
  }
}

.a-h3,
h3 {
  @include font(4rem, 5rem);
  font-weight: 700;
  margin-bottom: $space-m;

  @media #{$mq-md-max} {
    @include font(3rem, 4rem);
  }

  @media #{$mq-sm-max} {
    @include font(2.75rem, 4rem);
  }
}

.a-h4,
h4 {
  @include font(3rem, 4rem);
  font-weight: 700;
  margin-bottom: $space-m;

  @media #{$mq-md-max} {
    @include font(2.5rem, 3rem);
  }

  @media #{$mq-sm-max} {
    @include font(2.25rem, 3rem);
  }
}

.a-lead-type {
  @include font(3rem, 4rem);
  font-weight: $default-font-weight;
  margin-bottom: $space-s;

  @media #{$mq-md-max} {
    @include font(2.5rem, 3rem);
  }

  @media #{$mq-sm-max} {
    @include font(2.25rem, 3rem);
  }
}

p {
  @include font();
  font-weight: $default-font-weight;
  margin-bottom: $space-s;
}

b,
strong,
.v-bold {
  font-weight: 900;
}

i,
em,
.v-italic {
  font-style: italic;
}

sup,
sub {
  font-size: 75%;
}

a {
  color: $color-primary-light;
  text-decoration: none;

  &:hover,
  &:active,
  &.v-active {
    color: $color-primary-light;
  }
}

ul,
ol {
  margin-bottom: 1rem;
}

label {
  @include font(1.5rem, 2rem);
  margin-bottom: 1rem;

  &.a-error-type {
    display: none;
    width: 100%;
    color: $color-warning-medium;
    margin-bottom: 2rem;
  }
}

.a-attribution-type {
  @include font(1rem, 2rem);
}

.a-quote-type {
  @include font(3rem, 4rem);
}

.a-quotation-mark-type {
  @include font(3rem, 3rem);
}
