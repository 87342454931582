@import "./Resources/variables";
.o-calendar {
  .previous,
  .next,
  .previousVertical,
  .nextVertical {
    font-size: 4rem;
    text-align: center;
    position: relative;

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .previousVertical {
    margin-bottom: 20px;

    span:after {
      content: "previous week";
      font-size: 2.5rem;
      color: black;
      padding-left: 1rem;
    }
  }

  .nextVertical {
    margin-top: 20px;

    span:before {
      content: "next week";
      font-size: 2.5rem;
      color: black;
      padding-right: 1rem;
    }
  }

  .times,
  .days {
    p {
      text-align: center;
      border-radius: 4px;
      border: 1px solid $color-primary-dark;
      transition: background-color 0.5s;

      &:hover {
        background-color: $color-primary-light;
        cursor: pointer;
      }
    }

    .m-time,
    .m-day {
      p {
        border: 1px solid $color-primary-light;

        &.active {
          background-color: $color-primary-light;
        }
      }

      .match-height {
        @media screen and (min-width: 800px) {
          min-height: 115px;
        }

        @media screen and (min-width: 1100px) {
          min-height: 80px;
        }
      }

      &.disabled {
        p {
          border-color: $color-greyscale-2;
          background: $color-greyscale-2;
          color: $color-greyscale-6;
          cursor: not-allowed;
        }
      }

      &.selected {
        p {
          background-color: $color-primary-dark;
        }
      }

      &.failure {
        p {
          border: $color-warning-dark;
          background-color: $color-warning-light;
          animation: wobble 0.2s ease-in-out 5;
        }
      }
    }
  }

  .days {
    p {
      border: none;
    }
  }
}

@keyframes wobble {
  25% {
    transform: translateX(-5px);
  }
  75% {
    transform: translate(5px);
  }
}

@media screen and (min-width: 751px) {
  .nextVertical,
  .previousVertical {
    display: none;
  }
}

@media screen and (max-width: 750px) {
  .next,
  .previous {
    display: none;
  }
}
