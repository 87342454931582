@import "Resources/variables";

header {
  border-bottom: 2px solid $color-greyscale-2;

  &.o-header {
    padding: 2rem 0;
    position: relative;
    width: 100%;
    z-index: 1000;
    background: $color-greyscale-1;
    height: unset;
  }

  .logo {
    height: 6rem;
  }

  .nav-link {
    padding: 0 $space-xs;
    color: $color-text-light;

    &:hover {
      color: $color-primary-dark;
    }
  }
}
