@import "Resources/variables";
//Inputs Styles
input[type="email"],
input[type="number"],
input[type="password"],
input[type="text"],
select,
textarea,
.ant-select-selector,
.a-input-file-label,
.ant-picker,
.ant-select {
  // height: 6rem;
  // padding: 1rem 2rem;
  color: $color-text-medium;
  background-color: white;
  outline: 0;
  border: $default-border-size solid $color-border-medium;
  drop-shadow: none;
  border-radius: 0;
  @include tr();
  &.v-input-warning {
    border: $default-border-size solid $color-warning-medium;
    color: $color-warning-medium;
    &::-webkit-input-placeholder {
      color: $color-warning-medium;
    }
    &::-moz-placeholder {
      color: $color-warning-medium;
    }
    &:-ms-input-placeholder {
      color: $color-warning-medium;
    }
    &:-moz-placeholder {
      color: $color-warning-medium;
    }
  }
  &:not(.no-hover):hover {
    @include tr();
    drop-shadow: $shadow-2;
  }
  &:not(.no-focus):not(.v-input-warning):focus {
    @include tr();
    border: $default-border-size solid $color-primary-main;
  }
  &.v-input-disabled,
  &[disabled],
  &[disabled="disabled"] {
    background-color: $color-greyscale-2;
    pointer-events: none;
  }
  &::-webkit-input-placeholder {
    color: $color-greyscale-4;
  }
  &::-moz-placeholder {
    color: $color-greyscale-4;
  }
  &:-ms-input-placeholder {
    color: $color-greyscale-4;
  }
  &:-moz-placeholder {
    color: $color-greyscale-4;
  }

  &.v-remove-box {
    border: none;
    background: transparent;

    .ant-select-selector {
      border: none;
      background: none !important;
    }

    .ant-picker-suffix,
    .ant-select-arrow {
      display: none;
    }
  }

  &.v-number {
    width: 100px;
  }
}
// // .ant-select-selector,
// // .ant-picker {
// //   height: unset !important;
// //   padding: 1rem !important;
// // }
// .v-input-warning {
//   label {
//     color: $color-warning-medium;
//   }
// }
// select {
//   cursor: pointer;
//   background-position: right 2rem center;
//   background-size: 1rem;
//   background-image: url($img-select-arrow);
//   background-repeat: no-repeat;
//   &.v-multiple {
//     height: auto;
//   }
// }
// textarea {
//   height: 28rem;
//   padding: 2rem;
// }
hr {
  width: 60%;
  border: 0;
  height: 0.125rem;
  background-color: $color-greyscale-4;
  margin: 1rem auto 2rem;
}
// //Radio & Checkbox Buttons
// [type="radio"],
// [type="checkbox"] {
//   position: absolute;
//   left: -9999px;
//   padding-top: 0.25rem;
//   &:checked + label:after {
//     opacity: 1;
//     transform: scale(1);
//   }
//   &:not(:checked) + label:after {
//     opacity: 0;
//     transform: scale(0);
//   }
//   & + label {
//     position: relative;
//     padding-left: 4rem;
//     line-height: 2rem;
//     cursor: pointer;
//     display: inline-block;
//     &:before {
//       content: "";
//       position: absolute;
//       left: 0;
//       top: 0;
//       width: 2rem;
//       height: 2rem;
//       border: $default-border-size solid $color-border-medium;
//       background: white;
//     }
//     &:after {
//       content: "";
//       width: 2rem;
//       position: absolute;
//       border-radius: 50%;
//       @include tr();
//     }
//   }
// }
// [type="radio"] {
//   & + label {
//     &:before {
//       border-radius: 50%;
//     }
//     &:after {
//       height: 1rem;
//       width: 1rem;
//       background: $color-primary-light;
//       top: 0.625rem;
//       left: 0.625rem;
//       border-radius: 50%;
//     }
//   }
// }
// [type="checkbox"] {
//   & + label {
//     &:before {
//       border-radius: 0.25rem;
//     }
//     &:after {
//       content: "\f00c";
//       @include font-awesome-family(900);
//       font-size: 1.5rem;
//       height: 2rem;
//       line-height: 2rem;
//       color: $color-primary-light;
//       left: 0.125rem;
//       top: 0.125rem;
//       text-align: center;
//     }
//   }
// }
// .a-input-file-label {
//   padding-top: 2rem;
//   color: $color-greyscale-4;
// }
input[disabled],
.ant-select-disabled span {
  color: $color-greyscale-7 !important;
}

.a-sigCanvas {
  background: white;
}

.a-hidden {
  display: none;
}
