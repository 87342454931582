.o-cookie-warning {
  position: fixed;
  width: 100%;
  bottom: -100%;
  left: 0;
  background-color: $color-greyscale-6;
  z-index: 100;
  padding: $space-m 0;
  @include tr();

  &.show-cookie-popup {
    bottom: 0;
    opacity: 1;
    @include tr();
  }
}
