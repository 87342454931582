@import "Resources/variables";

.m-social-sharing-block {
}

.m-breadcrumbs-block {
}

//form
.m-form-block {
  width: 100%;
}

.m-form-file-input-block {
  width: 100%;

  input[type="file"] {
    height: 0;
    overflow: hidden;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
}

.m-keyword-search-results-block {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 10;

  &.v-hide {
    display: none;
  }
}

.m-dashboard-navigation {
  .logo {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .trigger {
    svg {
      margin: 3rem;
      fill: $color-greyscale-1;
    }
  }
}

.something-relative {
  position: relative;

  .something-absolute {
    position: relative;
  }
}

@media #{$mq-sm-max} {
  .m-small-screen-stack {
    display: flex;
    flex-direction: column;

    &.button {
      width: 85vw;
    }
  }

  .v-button-80 {
    width: 80%;
  }
}
