.o-form,
.o-form-loading,
.o-form-success,
.o-form-error {
  width: 100%;
}

.o-form-loading {
  width: 100%;
  position: relative;
  min-height: 20rem;
  @include loading-adjust-huener();
}

.o-form {
  .container {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-form-item-label {
    display: block;
    line-height: 6rem;
    width: 30%;
  }
}
