﻿/*Border Utility Classes*/
.b-xxs {
  border: $border-xxs-value;
}

.bt-xxs {
  border: $border-xxs-value;
}

.bt-xxs {
  border-top: $border-xxs-value;
}

.br-xxs {
  border-right: $border-xxs-value;
}

.bb-xxs {
  border-bottom: $border-xxs-value;
}

.bl-xxs {
  border-left: $border-xxs-value;
}
.border-none {
  border: none !important;
}

/*Drop Shadow Utility Classes*/
.shadow-2 {
  box-shadow: $shadow-2;
}

.shadow-6 {
  box-shadow: $shadow-6;
}

.shadow-12 {
  box-shadow: $shadow-12;
}

.shadow-24 {
  box-shadow: $shadow-24;
}
