.ant-space {
  display: block;
}
.ant-table-wrapper {
  &:not(.no-hover) {
    .ant-table-row {
      &:hover {
        cursor: pointer;
      }
    }
  }
}

.m-input {
  &.v-additional-info {
    padding: 0;
    background: none;
    > .ant-collapse-item {
      border: none;

      > .ant-collapse-header {
        padding: 0;

        span {
          &.anticon {
            top: 25%;
            left: 0;
          }
        }
      }
    }
  }

  label {
    padding-left: 20px;
  }
}

.ant-popover-inner {
  padding: 3rem;
}

.v-collapse-no-style {
  background: transparent;
  > div {
    border: none !important;
  }
}

.rteEditor {
  height: 200px;
}

.ant-picker-year-btn,
.ant-picker-month-btn {
  transition: all 0.3s ease-in-out !important;
  &:hover {
    transform: scale(1.2) !important;
  }
}

.ant-form-item {
  margin-bottom: 6px !important;
}

.ant-selector-selector {
  width: 20vh;
}

.layout-white {
  background: white;
}

.v-ant-layout-header {
  height: auto;
}

.v-ant-form-item-label {
  white-space: normal !important;
  text-align: left !important;
}

[title="numberOfNonWheelchairDisabled"] {
  word-break: break-word;
}

@media screen and (min-width: 800px) {
  .ant-steps-responsive {
    display: flex !important;
    flex-direction: row !important;
  }
}

// .react-date-picker__inputGroup {
//   padding: 5px !important;
// }

// .react-date-picker__inputGroup__month,
// .react-date-picker__inputGroup__day {
//   width: 40px !important;
//   padding: 2px;
// }

// .react-date-picker__inputGroup__year {
//   width: 80px !important;
//   padding: 2px;
// }
